import React, { FC } from 'react';

import AppContainer from '../components/app-container/app-container';
import WorkInProgress from '../ui/work-in-progress/work-in-progress';

const Lab: FC = () => (
  <AppContainer>
    <WorkInProgress />
  </AppContainer>
);

export default Lab;
