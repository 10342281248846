import React from 'react';

import Emoji from '../../components/emoji/emoji';

const WorkInProgress = () => (
  <>
    Not much for now, please come back later...
    <Emoji emoji={'🚧'} label={'Work in progress'} />
  </>
);

export default WorkInProgress;
